import Vue from 'vue'

import vuetify from './plugins/vuetify'

import App from './App.vue'

Vue.config.productionTip = false

import './assets/css/tailwind.css'

import { CustomStore } from './store/store'
import { CustomRouter } from '@/router'

import { ApiService } from './services/api.service'

let API_BASEURL = process.env.VUE_APP_API_BASEURL
if (process.env.NODE_ENV === 'development') {
    API_BASEURL = 'http://localhost:8080'
}
ApiService.init(API_BASEURL)
Vue.prototype.$http = ApiService

import { IconService } from '@/services/icons.service'
Vue.prototype.$icons = IconService

import { RulesService } from '@/services/rules.service'
Vue.prototype.$rules = RulesService

import { UtilsService } from '@/services/utils.service'
Vue.prototype.$utils = UtilsService

import { TokenService } from './services/token.service'
TokenService.loadToken()

const startup = async function () {
    let loginSuccess = false

    if (TokenService.hasToken()) {
        await CustomStore.dispatch('auth/verify')
        loginSuccess = true
    }

    if (!loginSuccess) {
        ApiService.removeAuthHeader()
        TokenService.removeToken()
        TokenService.removeIdentity()

        CustomStore.commit('auth/loginFailed')
    }

    new Vue({
        vuetify,
        router: CustomRouter,
        store: CustomStore,
        render: (h) => h(App)
    }).$mount('#app')
}

try {
    startup()
} catch (err) {
    console.log('could not start application')
    console.log(err)
}
