<template>
    <div>Video Upload Page</div>
</template>

<script>
    export default {
        name: 'VideoUploadPage'
    }
</script>

<style scoped>

</style>