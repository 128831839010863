import { render, staticRenderFns } from "./AddTalkPage.vue?vue&type=template&id=0b8dda16&scoped=true&"
import script from "./AddTalkPage.vue?vue&type=script&lang=js&"
export * from "./AddTalkPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8dda16",
  null
  
)

export default component.exports