<template>
    <div>Settings</div>
</template>

<script>
    export default {
        name: 'SettingsPage'
    }
</script>

<style scoped>

</style>