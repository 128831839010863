const UtilsService = {
    formatDuration: function (durationString) {
        return durationString.substr(2).replace('H', 'h ').replace('M', 'm ').replace('S', 's ')
    },
    formatDate: function (dateString) {
        if (!dateString) {
            return ''
        }

        let date = new Date(dateString)

        return (
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
            ':' +
            (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) +
            ' ' +
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            '.' +
            (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
            '.' +
            date.getFullYear()
        )
    }
}

export { UtilsService }
