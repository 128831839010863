<template>
    <div id="test" class="tw-h-screen tw-pt-40">
        <v-card v-if="state === 0" elevation="5" class="tw-inline-block tw-p-4">
            <div class="tw-text-2xl tw-font-bold tw-py-4">Login</div>
            <v-form ref="login-form">
                <v-col>
                    <v-text-field
                        v-model="email"
                        type="text"
                        label="E-Mail"
                        prepend-icon="mdi-email-outline"
                        :rules="[rules.required, rules.mailValid]"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        prepend-icon="mdi-lock-outline"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.passwordCorrect]"
                        :hint="wrongPassword ? 'The password is incorrect' : ''"
                        required
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <div
                        class="tw-text-xs tw-text-right tw-cursor-pointer hover:tw-underline"
                        @click="changeStateResetPassword"
                    >
                        Forgot password?
                    </div>
                </v-col>
                <v-col>
                    <v-btn elevation="2" color="primary" @click="loginWithCredentials">
                        <div v-if="!loading.login">Login</div>
                        <v-progress-circular
                            v-else
                            indeterminate
                            width="2"
                            size="20"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-col>
                <v-btn elevation="2" color="primary" small @click="loginAsGuest"
                    >Login as Guest</v-btn
                >
            </v-col>
            <!--
            <v-divider class="my-3"></v-divider>
            <div
                class="tw-cursor-pointer hover:tw-underline tw-text-xs tw-pt-10"
                @click="changeStateCreateAccount"
            >
                Create an account
            </div>
            -->
        </v-card>

        <v-card v-else-if="state === 1" elevation="5" class="tw-inline-block tw-p-4">
            <div class="tw-text-2xl tw-font-bold tw-py-4">Create a new account</div>
            <v-form ref="register-form">
                <v-col>
                    <v-text-field
                        v-model="email"
                        type="text"
                        label="E-Mail"
                        prepend-icon="mdi-email-outline"
                        :rules="[rules.required, rules.mailValid, rules.mailTaken]"
                        required
                        autocomplete="new-password"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        prepend-icon="mdi-lock-outline"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        counter
                        required
                        autocomplete="new-password"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-text-field
                        v-model="password2"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        prepend-icon="mdi-lock-outline"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.matches]"
                        required
                        autocomplete="new-password"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn elevation="2" color="primary" @click="createNewAccount">
                        <div v-if="!loading.register">Create</div>
                        <v-progress-circular
                            v-else
                            indeterminate
                            width="2"
                            size="20"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-form>
            <div
                class="tw-cursor-pointer hover:tw-underline tw-text-xs tw-pt-10"
                @click="changeStateLogin"
            >
                back to login
            </div>
        </v-card>

        <v-card v-else elevation="5" class="tw-inline-block tw-p-4">
            <div class="tw-text-2xl tw-font-bold tw-py-4">Reset password</div>
            <div class="tw-text-xs">Not available yet, you're on your own :(</div>
            <div
                class="tw-cursor-pointer hover:tw-underline tw-text-xs tw-pt-10"
                @click="changeStateLogin"
            >
                back to login
            </div>
        </v-card>

        <v-snackbar v-model="registerSuccess" timeout="5000" color="success" tile top>
            Account successfully created

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="registerSuccess = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: 'LoginPage',
        data() {
            return {
                // 0 = login, 1 = create account, 2 = reset password
                state: 0,
                loading: {
                    login: false,
                    register: false
                },
                email: '',
                password: '',
                password2: '',
                loginErrors: {
                    passwordWrong: false
                },
                registerSuccess: false,
                showPassword: false,
                wrongPassword: false,
                mailTaken: false,
                rules: {
                    required: (value) => !!value || 'Required.',
                    min: (v) => v.length >= 5 || 'Min 5 characters',
                    matches: (v) => v === this.password || 'Passwords do not match',
                    mailValid: (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    // login password wrong
                    passwordCorrect: () =>
                        !this.loginErrors.passwordWrong || 'Password is incorrect',
                    // register email taken
                    mailTaken: () => !this.mailTaken || 'Email is already taken'
                }
            }
        },
        methods: {
            resetInputFields: function () {
                this.password = ''
                this.password2 = ''
                this.email = ''
                this.wrongPassword = false
            },
            loginWithCredentials: async function () {
                const context = this

                if (context.loading.login) {
                    return
                }

                context.loginErrors.passwordWrong = false

                if (this.$refs['login-form'].validate()) {
                    const redirect = this.$router.history.current.query.redirect || '/'

                    context.loading.login = true

                    const statusCode = await context.$store.dispatch('auth/login', {
                        email: context.email,
                        password: context.password
                    })

                    context.loading.login = false

                    switch (statusCode) {
                        case 200:
                            context.$router
                                .push({
                                    path: redirect
                                })
                                .catch(() => {})
                            break
                        case 401:
                            context.loginErrors.passwordWrong = true
                            this.$refs['login-form'].validate()
                            break
                    }
                }
            },
            loginAsGuest: async function () {
                const context = this

                const redirect = this.$router.history.current.query.redirect || '/'

                const statusCode = await context.$store.dispatch('auth/login', {
                    email: 'guest@user.de',
                    password: 'guestuserpassword1234'
                })

                switch (statusCode) {
                    case 200:
                        context.$router
                            .push({
                                path: redirect
                            })
                            .catch(() => {})
                        break
                    default:
                        console.log('guest user login failed')
                        break
                }
            },
            changeStateCreateAccount: function () {
                this.resetInputFields()
                this.state = 1
            },
            changeStateLogin: function () {
                this.resetInputFields()
                this.state = 0
            },
            changeStateResetPassword: function () {
                this.resetInputFields()
                this.state = 2
            },
            createNewAccount: async function () {
                console.log('in create new account')
                this.mailTaken = false

                const context = this

                if (this.$refs['register-form'].validate()) {
                    context.loading.register = true

                    context.$http
                        .post('/auth/registerUser', {
                            email: context.email,
                            password: context.password
                        })
                        .then(() => {
                            context.loading.register = false

                            context.state = 0
                            context.registerSuccess = true
                        })
                        .catch((err) => {
                            context.loading.register = false

                            const statusCode = err.response.data.status.value

                            if (statusCode === 301) {
                                this.mailTaken = true
                                this.$refs['register-form'].validate()
                            }

                            console.log(err.response)
                        })
                }
            }
        }
    }
</script>

<style scoped>
    #test {
        margin: 0;
    }
</style>