<template>
    <div>
        <v-container>
            <v-card>
                <v-card-title>
                    <div>Your playlists</div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" small outlined @click="openNewPlaylistDialog"
                        >add new playlist</v-btn
                    >
                </v-card-title>

                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Playlist name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="playlist in playlists"
                                    :key="playlist.id"
                                    class="text-left tw-cursor-pointer"
                                    @click="goToPlaylistDetailsPage(playlist.id)"
                                >
                                    <td>{{ playlist.name }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-container>

        <!-- create new playlist -->
        <v-dialog v-model="newPlaylistDialog.show" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add new playlist</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="add-new-playlist">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Playlist name"
                                        v-model="newPlaylistDialog.name"
                                        :rules="[
                                            this.$rules.required,
                                            this.$rules.alphanumericWithSpaces,
                                            this.$rules.lengthAbove3,
                                            this.$rules.lengthBelow40
                                        ]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text outlined @click="closeNewPlaylistDialog">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text outlined @click="addNewPlaylist">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'PlaylistPage',
        data() {
            return {
                edit: false,
                newPlaylistDialog: {
                    show: false,
                    name: ''
                },
                success: {
                    show: false,
                    text: '',
                    timeout: 3000
                },
                error: {
                    show: false,
                    text: '',
                    timeout: 3000
                },
                playlists: undefined
            }
        },
        methods: {
            getPlaylists: function () {
                const context = this

                context.$http
                    .get('/video/getPlaylists')
                    .then((response) => {
                        context.playlists = response.data.playlists

                        // sets the list index of each video in each playlist
                        for (let playlist of context.playlists) {
                            for (let i = 0; i < playlist.videos.length; i++) {
                                playlist.videos[i].index = i + 1
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            goToPlaylistDetailsPage: function (playlistId) {
                this.$router.push({
                    name: 'playlistDetailsPage',
                    params: {
                        id: playlistId
                    }
                })
            },
            openNewPlaylistDialog: function () {
                this.newPlaylistDialog.show = true
            },
            closeNewPlaylistDialog: function () {
                this.newPlaylistDialog.show = false
                this.newPlaylistDialog.name = ''
                this.$refs['add-new-playlist'].resetValidation()
            },
            addNewPlaylist: function () {
                const context = this

                if (this.$refs['add-new-playlist'].validate()) {
                    context.$http
                        .post('/video/createNewPlaylist', {
                            name: context.newPlaylistDialog.name
                        })
                        .then(() => {
                            context.closeNewPlaylistDialog()

                            context.success.text = 'Successfully added new playlist'
                            context.success.show = true

                            context.getPlaylists()
                        })
                        .catch((err) => {
                            context.error.text = err.response.data.payload
                            context.error.show = true
                        })
                }
            }
        },
        created() {
            this.getPlaylists()
        }
    }
</script>

<style scoped>

</style>