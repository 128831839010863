<template>
    <div>
        <div class="text-2xl">Add a YouTube Video to the talk database</div>

        <div v-if="state === 0">
            <input
                type="text"
                size="50"
                placeholder="YouTube URL"
                class="border border-gray-400 p-2 mt-10"
                v-model="url"
            />

            <div class="mt-10">
                <div v-if="meta">
                    <div
                        class="border border-gray-400 rounded-sm inline-block px-5 py-3 text-left max-w-lg"
                    >
                        <div class="text-xs">Title</div>
                        <div class="text-black">
                            {{ meta.title }}
                        </div>
                        <div class="text-xs mt-3">Channel</div>
                        <div class="text-black">{{ meta.channel }}</div>
                        <div class="text-xs mt-3">Views</div>
                        <div class="text-black">{{ meta.views }}</div>
                        <div class="text-xs mt-3">Upvotes</div>
                        <div class="text-black">{{ meta.upvotes }}</div>
                        <div class="text-xs mt-3">Uploaded</div>
                        <div class="text-black">{{ meta.uploadTimestamp }}</div>
                        <div class="text-xs mt-3">Duration</div>
                        <div class="text-black">{{ meta.duration }}</div>
                    </div>

                    <div v-if="meta.exists === 'QUEUE'" class="mt-10">
                        <div class="text-red-600">Video already in Queue!</div>
                        <div class="button-8" @click="switchToIndexPage">View Queue</div>
                    </div>

                    <div v-else-if="meta.exists === 'DATABASE'" class="mt-10">
                        <div class="text-red-600">Video already in Database!</div>
                        <div class="button-8" @click="goToVideo">Go to Video</div>
                    </div>

                    <div v-else class="mt-10">
                        <div class="button-8" @click="uploadVideo">Add to Database</div>
                    </div>
                </div>

                <div v-else-if="url && isLoading">Loading...</div>

                <div v-else-if="url" class="text-red-600">
                    No video found for this URL. <br />
                    The URL must start with: https://www.youtube.com/watch?v=
                </div>
            </div>
        </div>

        <div v-else class="mt-20">
            <div class="text-green-700">Successfully added the video to the processing queue!</div>
            <div class="button-8 mt-5" @click="switchToIndexPage">View Queue</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AddYoutubeVideoPage',
        data() {
            return {
                // 0 = input url, 1 = success
                state: 0,
                url: '',
                meta: undefined,
                isLoading: false
            }
        },
        methods: {
            getVideoMeta: function () {
                if (!this.url.startsWith('https://www.youtube.com/watch?v=')) {
                    return
                }

                this.isLoading = true

                const context = this

                this.$http
                    .post('/upload/youtubeMeta', {
                        url: this.url
                    })
                    .then((response) => {
                        context.meta = response.data
                        context.isLoading = false
                    })
                    .catch(() => {
                        context.meta = undefined
                        context.isLoading = false
                    })
            },
            uploadVideo: function () {
                const context = this

                this.$http
                    .post('/upload/youtube', {
                        url: this.url
                    })
                    .then(() => {
                        context.state = 1
                    })
                    .catch(() => {
                        context.state = 0
                        context.url = ''
                        context.meta = undefined
                    })
            },
            goToVideo: function () {
                this.$router.push({
                    name: 'talkDetailsPage',
                    params: {
                        id: this.meta.databaseId
                    }
                })
            },
            switchToIndexPage: function () {
                this.$router.push({
                    name: 'indexPage'
                })
            }
        },
        watch: {
            url: function () {
                this.getVideoMeta()
            }
        }
    }
</script>

<style scoped>

</style>