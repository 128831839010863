<template>
    <div id="app">
        <v-app :style="{ background: '#efeff0' }">
            <v-app-bar fixed color="#17255A" dark>
                <v-app-bar-nav-icon
                    v-if="showNavbarIcons"
                    @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>

                <v-toolbar-title class="tw-mr-10">
                    <div>X-SIST</div>
                    <div class="tw-text-xs">conteXt Search In Scientific Talks</div>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <div v-if="showNavbarIcons">
                    <div class="tw-text-xs">Logged in as</div>
                    <div v-if="username === 'guest@user.de'">Guest</div>
                    <div v-else>
                        {{ username }}
                    </div>
                </div>

                <v-btn icon v-if="showNavbarIcons">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" icon> mdi-account </v-icon>
                        </template>
                        <span>Profile</span>
                    </v-tooltip>
                </v-btn>

                <v-btn icon v-if="showNavbarIcons">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="logout"> mdi-logout </v-icon>
                        </template>
                        <span>Logout</span>
                    </v-tooltip>
                </v-btn>
            </v-app-bar>

            <v-navigation-drawer v-model="drawer" absolute bottom temporary>
                <v-list nav class="tw-text-left" dense>
                    <v-list-item-group
                        v-model="group"
                        active-class="deep-purple--text text--accent-4"
                    >
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-home</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Home</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-database</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Video Archive</v-list-item-title>
                        </v-list-item>

                        <!--
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-cloud-upload</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Add video to database</v-list-item-title>
                        </v-list-item>
                        -->

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-cloud-search</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Search by keywords</v-list-item-title>
                        </v-list-item>

                        <!--
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-playlist-play</v-icon>
                            </v-list-item-icon>

                            <v-list-item-title>Playlists</v-list-item-title>
                        </v-list-item>
                        -->
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

            <router-view class="tw-w-screen tw-h-full tw-mt-24 tw-overflow-hidden"></router-view>
        </v-app>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'App',
        data() {
            return {
                drawer: false,
                group: 0
            }
        },
        methods: {
            switchToHomePage: function () {
                if (this.$route.name === 'indexPage') {
                    return
                }
                this.$router.push({
                    name: 'indexPage'
                })
            },
            switchToSearchContextPage: function () {
                if (this.$route.name === 'searchContextPage') {
                    return
                }
                this.$router.push({
                    name: 'searchContextPage'
                })
            },
            switchToArchivePage: function () {
                if (this.$route.name === 'archivePage') {
                    return
                }
                this.$router.push({
                    name: 'archivePage'
                })
            },
            switchToDatabasePage: function () {
                if (this.$route.name === 'databasePage') {
                    return
                }
                this.$router.push({
                    name: 'databasePage'
                })
            },
            switchToAddTalkPage: function () {
                if (this.$route.name === 'addTalkPage') {
                    return
                }
                this.$router.push({
                    name: 'addTalkPage'
                })
            },
            switchToPlaylistPage: function () {
                if (this.$route.name === 'playlistPage') {
                    return
                }
                this.$router.push({
                    name: 'playlistPage'
                })
            },
            switchToSettingsPage: function () {
                if (this.$route.name === 'settingsPage') {
                    return
                }
                this.$router.push({
                    name: 'settingsPage'
                })
            },
            updateNavbar: function (routeName) {
                switch (routeName) {
                    case 'homePage':
                        this.group = 0
                        break
                    case 'archivePage':
                        this.group = 1
                        break
                    case 'searchContextPage':
                        this.group = 2
                        break
                    // case 'addTalkPage':
                    // this.group = 2
                    // break
                    // case 'playlistPage':
                    // this.group = 4
                    // break
                    default:
                        this.group = -1
                }
            },
            logout: function () {
                this.$store.dispatch('auth/logout', {
                    targetRoute: '/login'
                })
            }
        },
        computed: {
            showNavbarIcons: function () {
                return this.$route.name !== 'loginPage'
            },
            ...mapState('auth', {
                username: (state) => state.username
            })
        },
        mounted() {
            this.updateNavbar(this.$route.name)
        },
        watch: {
            group() {
                this.drawer = false

                switch (this.group) {
                    case 0:
                        this.switchToHomePage()
                        break
                    case 1:
                        this.switchToArchivePage()
                        break
                    case 2:
                        this.switchToSearchContextPage()
                        break
                    /*
                    case 3:
                        this.switchToAddTalkPage()
                        break
                    case 4:
                        this.switchToPlaylistPage()
                        break
                    */
                }
            },
            // used to update the drawer navigation items
            $route: function () {
                this.updateNavbar(this.$route.name)
            }
        }
    }
</script>

<style>
    @font-face {
        font-family: 'SourceSansPro';
        src: local('SourceSansPro'),
            url('assets/font/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
    }

    #app {
        font-family: Roboto, Arial;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    .button-8 {
        background-color: #e1ecf4;
        border-radius: 3px;
        border: 1px solid #7aa7c7;
        box-shadow: rgba(255, 255, 255, 0.7) 0 1px 0 0 inset;
        box-sizing: border-box;
        color: #39739d;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system, system-ui, 'Segoe UI', 'Liberation Sans', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.15385;
        outline: none;
        padding: 8px 0.8em;
        position: relative;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: baseline;
        white-space: nowrap;
    }
    .button-8:hover,
    .button-8:focus {
        background-color: #b3d3ea;
        color: #2c5777;
    }
    .button-8:focus {
        box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
    }
    .button-8:active {
        background-color: #a0c7e4;
        box-shadow: none;
        color: #2c5777;
    }
</style>
