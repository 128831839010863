<template>
    <div>
        <v-container>
            <v-card v-if="playlistInfo">
                <v-card-title>
                    {{ playlistInfo.name }}
                    <v-spacer></v-spacer>
                    <v-btn small outlined class="tw-mx-2" @click="openRenamePlaylistDialog">
                        rename
                    </v-btn>
                    <v-btn
                        small
                        color="red"
                        outlined
                        class="tw-mx-2"
                        @click="openDeletePlaylistDialog"
                    >
                        delete
                    </v-btn>
                </v-card-title>
                <div class="tw-text-xs text-left tw-pl-4">
                    Created: {{ $utils.formatDate(playlistInfo.dateCreated) }}
                </div>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        hide-default-footer
                        :items="videos"
                        v-sortable-data-table
                        @sorted="saveOrder"
                        item-key="id"
                    >
                        <template v-slot:item.controls="props">
                            <v-btn
                                x-small
                                color="red"
                                outlined
                                @click="openDeleteItemFromPlaylistDialog(props.item.id)"
                            >
                                delete
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>

        <!-- rename existing playlist -->
        <v-dialog v-model="renamePlaylistDialog.show" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Rename playlist</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="rename-playlist-form">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Playlist name"
                                        v-model="renamePlaylistDialog.name"
                                        :rules="[
                                            this.$rules.required,
                                            this.$rules.alphanumericWithSpaces,
                                            this.$rules.lengthAbove3,
                                            this.$rules.lengthBelow40
                                        ]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text outlined @click="closeRenamePlaylistDialog">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text outlined @click="renamePlaylist"
                        >Rename</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete playlist -->
        <v-dialog v-model="deletePlaylistDialog.show" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Delete the playlist</span>
                </v-card-title>
                <v-card-text> Do you really want to delete this playlist? </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text outlined @click="closeDeletePlaylistDialog">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text outlined @click="deletePlaylist"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete item from playlist -->
        <v-dialog v-model="deleteItemFromPlaylistDialog.show" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Delete this item</span>
                </v-card-title>
                <v-card-text>
                    Do you really want to delete this item from the playlist?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        outlined
                        @click="closeDeleteItemFromPlaylistDialog"
                    >
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text outlined @click="deleteItemFromPlaylist"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- generic success popup -->
        <v-snackbar v-model="success.show" :timeout="success.timeout" color="success" tile top>
            {{ success.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="success.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- generic error popup -->
        <v-snackbar v-model="error.show" :timeout="error.timeout" color="error" tile top>
            {{ error.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error.show = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import Sortable from 'sortablejs'

    export default {
        name: 'PlaylistDetailsPage',
        data() {
            return {
                playlistId: undefined,
                playlistInfo: undefined,
                videos: [],
                headers: [
                    {
                        text: '#',
                        value: 'index',
                        sortable: false,
                        width: '1%'
                    },
                    {
                        text: 'Title',
                        align: 'start',
                        sortable: false,
                        value: 'title'
                    },
                    {
                        text: 'Author',
                        align: 'start',
                        sortable: false,
                        value: 'author'
                    },
                    {
                        text: '',
                        value: 'controls',
                        sortable: false,
                        align: 'end',
                        width: '1%'
                    }
                ],
                renamePlaylistDialog: {
                    show: false,
                    name: ''
                },
                deletePlaylistDialog: {
                    show: false
                },
                deleteItemFromPlaylistDialog: {
                    show: false,
                    videoId: undefined
                },
                success: {
                    show: false,
                    text: '',
                    timeout: 3000
                },
                error: {
                    show: false,
                    text: '',
                    timeout: 3000
                }
            }
        },
        methods: {
            getPlaylistInfo: function () {
                const context = this

                context.$http
                    .post('/video/getPlaylistInfo', {
                        playlistId: context.playlistId
                    })
                    .then((response) => {
                        context.playlistInfo = response.data

                        context.videos = context.playlistInfo.videos

                        for (let i = 0; i < context.videos.length; i++) {
                            context.videos[i].index = i + 1
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            goToPlaylistPage: function () {
                this.$router.push({
                    name: 'playlistPage'
                })
            },
            openRenamePlaylistDialog: function () {
                this.renamePlaylistDialog.name = this.playlistInfo.name
                this.renamePlaylistDialog.show = true
            },
            closeRenamePlaylistDialog: function () {
                this.renamePlaylistDialog.show = false
                this.renamePlaylistDialog.name = ''
                this.$refs['rename-playlist-form'].resetValidation()
            },
            renamePlaylist: function () {
                const context = this

                if (this.$refs['rename-playlist-form'].validate()) {
                    context.$http
                        .post('/video/renamePlaylist', {
                            playlistId: context.playlistId,
                            name: context.renamePlaylistDialog.name
                        })
                        .then(() => {
                            context.closeRenamePlaylistDialog()

                            context.success.text = 'Successfully renamed the playlist'
                            context.success.show = true

                            context.getPlaylistInfo()
                        })
                        .catch((err) => {
                            context.error.text = err.response.data.payload
                            context.error.show = true
                        })
                }
            },
            openDeletePlaylistDialog: function () {
                this.deletePlaylistDialog.show = true
            },
            closeDeletePlaylistDialog: function () {
                this.deletePlaylistDialog.show = false
            },
            deletePlaylist: function () {
                const context = this

                context.$http
                    .post('/video/deletePlaylist', {
                        playlistId: context.playlistId
                    })
                    .then(() => {
                        context.closeDeletePlaylistDialog()

                        context.success.text = 'Successfully deleted the playlist'
                        context.success.show = true

                        context.goToPlaylistPage()
                    })
                    .catch((err) => {
                        context.error.text = err.response.data.payload
                        context.error.show = true
                    })
            },
            openDeleteItemFromPlaylistDialog: function (videoId) {
                this.deleteItemFromPlaylistDialog.videoId = videoId
                this.deleteItemFromPlaylistDialog.show = true
            },
            closeDeleteItemFromPlaylistDialog: function () {
                this.deleteItemFromPlaylistDialog.show = false
            },
            deleteItemFromPlaylist: function () {
                const context = this

                context.$http
                    .post('/video/deleteItemFromPlaylist', {
                        playlistId: context.playlistId,
                        videoId: context.deleteItemFromPlaylistDialog.videoId
                    })
                    .then(() => {
                        context.closeDeleteItemFromPlaylistDialog()

                        context.success.text = 'Successfully deleted the item'
                        context.success.show = true

                        context.getPlaylistInfo()
                    })
                    .catch((err) => {
                        context.error.text = err.response.data.payload
                        context.error.show = true
                    })
            },
            saveOrder(event) {
                const movedItem = this.videos.splice(event.oldIndex, 1)[0]
                this.videos.splice(event.newIndex, 0, movedItem)

                // reassigns the indices of the list items to the new order
                for (let i = 0; i < this.videos.length; i++) {
                    this.videos[i].index = i + 1
                }

                const context = this

                context.$http
                    .post('/video/reorderPlaylistItems', {
                        playlistId: context.playlistId,
                        videoIds: context.videos.map((item) => item.id)
                    })
                    .then(() => {
                        context.success.text = 'Changed the order'
                        context.success.show = true
                    })
                    .catch((err) => {
                        context.error.text = err.response.data.payload
                        context.error.show = true
                    })
            }
        },
        created() {
            this.playlistId = this.$route.params.id

            this.getPlaylistInfo()
        },
        directives: {
            sortableDataTable: {
                bind(el, binding, vnode) {
                    const options = {
                        animation: 150,
                        onUpdate: function (event) {
                            vnode.child.$emit('sorted', event)
                        }
                    }

                    Sortable.create(el.getElementsByTagName('tbody')[0], options)
                }
            }
        }
    }
</script>

<style scoped>

</style>