<template>
    <div>
        <v-container>
            <!--
            <v-row v-if="fromSearch">
                <v-btn small color="secondary" @click="goBackToArchivePage"
                    ><v-icon left> mdi-arrow-left-thin </v-icon>Back to Search Page</v-btn
                >
            </v-row>
            -->
            <v-row v-if="video">
                <v-col cols="12" sm="5">
                    <v-card class="tw-inline-block tw-p-2 tw-w-full">
                        <v-tabs v-model="tab" class="tw-mb-4">
                            <v-tabs-slider color="primary"></v-tabs-slider>

                            <v-tab>Info</v-tab>
                            <!--
                            <v-tab>Slide</v-tab>
                            -->
                            <v-tab>Playlist</v-tab>
                            <v-tab>Search by Slide</v-tab>
                            <v-tab>Search by Keyword</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <!-- INFO -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Video ID
                                                    </td>
                                                    <td
                                                        v-if="video.videoMetainfo.videoId"
                                                        class="tw-text-left"
                                                    >
                                                        {{ video.videoMetainfo.videoId }}
                                                    </td>
                                                    <td v-else class="tw-text-left">
                                                        No video ID available
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Title
                                                    </td>
                                                    <td class="tw-text-left">
                                                        {{ video.videoMetainfo.title }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Author
                                                    </td>
                                                    <td class="tw-text-left">
                                                        {{ video.videoMetainfo.author }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Duration
                                                    </td>
                                                    <td
                                                        v-if="video.videoMetainfo.duration"
                                                        class="tw-text-left"
                                                    >
                                                        {{
                                                            $utils.formatDuration(
                                                                video.videoMetainfo.duration
                                                            )
                                                        }}
                                                    </td>
                                                    <td v-else class="tw-text-left">
                                                        No duration available
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Source
                                                    </td>
                                                    <td class="tw-text-left">
                                                        <v-chip x-small color="secondary">
                                                            {{ video.videoMetainfo.source }}
                                                        </v-chip>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        URL
                                                    </td>
                                                    <td class="tw-text-left">
                                                        <a
                                                            :href="video.videoMetainfo.url"
                                                            target="_blank"
                                                            class="tw-cursor-pointer hover:tw-underline"
                                                        >
                                                            {{ video.videoMetainfo.url }}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Added
                                                    </td>
                                                    <td class="tw-text-left">
                                                        {{ $utils.formatDate(video.dateAdded) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tw-text-right font-weight-bold">
                                                        Actions
                                                    </td>
                                                    <td class="tw-text-left">
                                                        <v-chip
                                                            v-if="videoActions.isTranscribed"
                                                            x-small
                                                            color="primary"
                                                            class="tw-mr-1"
                                                        >
                                                            AUDIO ANALYZED
                                                        </v-chip>
                                                        <v-chip
                                                            v-if="videoActions.isOcred"
                                                            x-small
                                                            color="primary"
                                                            class="tw-mr-1"
                                                        >
                                                            VIDEO ANALYZED
                                                        </v-chip>
                                                        <v-chip
                                                            v-if="videoActions.isSlidesMapped"
                                                            x-small
                                                            color="primary"
                                                            class="tw-mr-1"
                                                        >
                                                            SLIDES ANALYZED
                                                        </v-chip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-tab-item>

                            <!-- SLIDES -->
                            <!--
                            <v-tab-item>
                                <v-card flat class="tw-w-full">
                                    <v-col>
                                        <div>Slide 1 of 123</div>
                                        <div>
                                            <img
                                                alt=""
                                                src="https://i.ibb.co/VCq9JbG/slide-0.png"
                                                class="tw-w-full"
                                            />
                                        </div>

                                        <v-row class="tw-mt-4">
                                            <v-btn small outlined color="primary"
                                                >Previous Slide</v-btn
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn small outlined color="primary">Next Slide</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-card>
                            </v-tab-item>
                            -->

                            <!-- PLAYLIST -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text>
                                        <!--
                                        <v-btn
                                            small
                                            outlined
                                            color="primary"
                                            @click="openAddToPlaylistDialog"
                                            >Add to playlist</v-btn
                                        >
                                        -->
                                        <v-simple-table
                                            dense
                                            class="tw-mt-2"
                                            v-if="playlists && playlists.length > 0"
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr
                                                        v-for="item in playlists"
                                                        :key="item.id"
                                                        class="tw-cursor-pointer text-left"
                                                        @click="goToPlaylistPage(item.id)"
                                                    >
                                                        <td>{{ item.name }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <div v-else class="tw-text-sm">Not in any playlist</div>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <!-- SEARCH BY SLIDE -->
                            <v-tab-item>
                                <v-card flat>
                                    <div v-if="slides.length === 0" class="tw-text-xs">
                                        No slides available
                                    </div>

                                    <v-list v-else class="tw-overflow-auto tw-h-96">
                                        <v-list-item
                                            :key="slide.slideNum"
                                            v-for="slide in slides"
                                            @click="jumpToSlide(slide)"
                                            class="hover:tw-bg-gray-100 tw-cursor-pointer tw-my-1"
                                        >
                                            <img
                                                alt=""
                                                :src="
                                                    $http.getBaseUrl() +
                                                    '/video/getSlideImage?videoId=' +
                                                    slide.videoId +
                                                    '&slideNum=' +
                                                    slide.slideNum
                                                "
                                                class="tw-h-32 tw-border tw-border-gray-500"
                                            />
                                            <v-list-item-content class="tw-text-sm tw-pl-4"
                                                >Slide {{ slide.slideNum + 1 }} of
                                                {{ slides.length }}</v-list-item-content
                                            >
                                        </v-list-item>
                                    </v-list>

                                    <v-col>
                                        <div>Sections:</div>
                                        <div v-if="slideSearchHits.length === 0" class="tw-text-xs">
                                            No sections found
                                        </div>
                                        <div
                                            :key="frame.id"
                                            v-for="(frame, i) in slideSearchHits"
                                            class="tw-cursor-pointer hover:tw-bg-gray-100 tw-py-3 tw-border"
                                            @click="jumpToFrame(frame)"
                                        >
                                            <div>Section {{ i + 1 }}</div>
                                            <div>
                                                {{
                                                    millisToTime(frame.startMillis) +
                                                    ' - ' +
                                                    millisToTime(frame.endMillis)
                                                }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-tab-item>

                            <!-- SEARCH BY KEYWORD -->
                            <v-tab-item>
                                <v-card flat>
                                    <v-col>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="keyword"
                                                label="Keyword"
                                                hint="Submit with <ENTER>"
                                                @keydown.enter="addKeywordInline"
                                                class="tw-w-1/3"
                                            ></v-text-field>
                                            <v-spacer></v-spacer>
                                        </v-row>

                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Keyword</th>
                                                        <th class="text-left">Weight</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        :key="keyword.keyword"
                                                        v-for="keyword in keywords"
                                                        class="tw-cursor-pointer"
                                                    >
                                                        <td class="tw-text-left tw-text-2xl">
                                                            {{ keyword.keyword }}
                                                        </td>
                                                        <!--
                                                        <td class="text-left">
                                                            {{ keyword.weight }}
                                                        </td>
                                                        -->
                                                        <td class="sm8">
                                                            <v-slider
                                                                v-model="keyword.weight"
                                                                min="1"
                                                                max="10"
                                                                step="1"
                                                                thumb-size="24"
                                                                ticks
                                                                thumb-label
                                                                class="tw-pt-4"
                                                            ></v-slider>
                                                        </td>
                                                        <td>
                                                            <v-btn text icon>
                                                                <v-tooltip bottom>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs
                                                                        }"
                                                                    >
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="
                                                                                deleteKeyword(
                                                                                    keyword
                                                                                )
                                                                            "
                                                                        >
                                                                            mdi-close
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>delete</span>
                                                                </v-tooltip>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <div
                                            v-if="keywords.length === 0"
                                            class="tw-mt-2 tw-text-xs"
                                        >
                                            No keywords entered
                                        </div>

                                        <v-row class="tw-my-2">
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" @click="searchKeywords">
                                                Search
                                            </v-btn>
                                        </v-row>

                                        <v-col v-if="keywordSections">
                                            <div>Results:</div>
                                            <div
                                                v-if="keywordSections.length === 0"
                                                class="tw-text-xs"
                                            >
                                                No sections found
                                            </div>
                                            <div
                                                v-else
                                                :key="section.startMillis"
                                                v-for="(section, i) in keywordSections"
                                                class="tw-cursor-pointer hover:tw-bg-gray-100 tw-py-3 tw-border"
                                                @click="jumpToSection(section)"
                                            >
                                                <div>Section {{ i + 1 }}</div>
                                                <div>
                                                    {{
                                                        millisToTime(section.startMillis) +
                                                        ' - ' +
                                                        millisToTime(section.endMillis)
                                                    }}
                                                </div>
                                                <div class="tw-text-xs">
                                                    {{ section.words.join(', ') }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>

                <!-- VIDEO ON RIGHT SIDE -->
                <v-col cols="12" sm="7">
                    <v-card v-if="video" class="tw-inline-block tw-p-2 tw-w-full">
                        <div>
                            <div class="tw-font-bold">
                                {{ video.videoMetainfo.title }}
                            </div>
                            <div class="tw-text-xs">
                                {{ video.videoMetainfo.author }}
                            </div>
                        </div>
                        <div v-if="video.videoMetainfo.source === 'YOUTUBE'" id="video-wrapper">
                            <iframe :src="youtube.videoSource" width="100%" height="100%"></iframe>
                        </div>
                        <div v-else id="video-wrapper-external">
                            <video
                                ref="video-player-external"
                                class="tw-border-black"
                                controls
                                :src="external.videoSource"
                            ></video>
                        </div>
                    </v-card>
                    <v-card v-else> Video not loaded.. </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- SECTION INFORMATION -->
        <v-dialog v-model="sectionInfo.show" max-width="400px">
            <v-card>
                <v-card-title> Section Information </v-card-title>

                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="tw-text-right">Name</td>
                                <td class="tw-text-left">
                                    {{ sectionInfo.obj.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="tw-text-right">Start</td>
                                <td class="tw-text-left">
                                    {{ Math.round(sectionInfo.obj.startMillis / 1000) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="tw-text-right">End</td>
                                <td class="tw-text-left">
                                    {{ Math.round(sectionInfo.obj.endMillis / 1000) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="tw-text-right">Author</td>
                                <td class="tw-text-left">
                                    {{ sectionInfo.obj.authorId }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-row class="tw-m-4">
                    <v-spacer></v-spacer>
                    <v-btn text icon class="tw-mx-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red"
                                    @click="jumpToSection(section)"
                                >
                                    mdi-thumb-down-outline
                                </v-icon>
                            </template>
                            <span>Vote down</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn text icon class="tw-mx-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="green"
                                    @click="jumpToSection(section)"
                                >
                                    mdi-thumb-up-outline
                                </v-icon>
                            </template>
                            <span>Vote up</span>
                        </v-tooltip>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="sectionAdd.show" persistent max-width="400px">
            <v-card>
                <v-card-title>Add a new section</v-card-title>

                <v-form ref="addSectionForm" class="tw-m-10">
                    <v-container>
                        <v-row>
                            <v-text-field
                                label="Name"
                                v-model="sectionAdd.name"
                                :rules="[this.$rules.required]"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-text-field
                                label="Start (seconds)"
                                type="number"
                                v-model="sectionAdd.start"
                                :rules="[this.$rules.required, this.$rules.positive]"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-text-field
                                label="End (seconds)"
                                type="number"
                                v-model="sectionAdd.end"
                                :rules="[this.$rules.required, this.$rules.positive]"
                            ></v-text-field>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="error" @click="sectionAdd.show = false"
                        >Cancel</v-btn
                    >
                    <v-btn small outlined color="primary" @click="addSection">Add Section</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog to add a new keyword for keyword search -->
        <v-dialog v-model="keywordAdd.show" persistent max-width="400px">
            <v-card>
                <v-card-title>Add a keyword to search</v-card-title>

                <v-form ref="addKeywordForm" class="tw-m-10">
                    <v-container>
                        <v-row>
                            <v-text-field
                                label="Keyword"
                                v-model="keywordAdd.keyword"
                                :rules="[this.$rules.required]"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-text-field
                                label="Weight"
                                type="number"
                                v-model="keywordAdd.weight"
                                :rules="[this.$rules.required, this.$rules.positive]"
                            ></v-text-field>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="error" @click="keywordAdd.show = false"
                        >Cancel</v-btn
                    >
                    <v-btn small outlined color="primary" @click="addKeyword">Add Keyword</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- add section error popup -->
        <v-snackbar
            v-model="sectionAdd.error.show"
            :timeout="sectionAdd.error.timeout"
            color="red accent-2"
            tile
            top
        >
            {{ sectionAdd.error.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="sectionAdd.error.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- add section success popup -->
        <v-snackbar
            v-model="sectionAdd.success.show"
            :timeout="sectionAdd.success.timeout"
            color="success"
            tile
            top
        >
            {{ sectionAdd.success.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="sectionAdd.success.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- add video to playlist -->
        <v-dialog v-model="addToPlaylistDialog.show" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add to playlist</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="add-to-playlist-form">
                        <v-select
                            v-model="addToPlaylistDialog.selectedPlaylist"
                            :items="addToPlaylistDialog.playlists"
                            label="Playlist"
                            :rules="[this.$rules.required]"
                            item-text="name"
                            item-value="id"
                        ></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text outlined @click="closeAddToPlaylistDialog">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text outlined @click="addToPlaylist">
                        Add to playlist
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- generic success popup -->
        <v-snackbar v-model="success.show" :timeout="success.timeout" color="success" tile top>
            {{ success.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="success.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- generic error popup -->
        <v-snackbar v-model="error.show" :timeout="error.timeout" color="error" tile top>
            {{ error.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="error.show = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: 'TalkDetailsPage',
        data() {
            return {
                id: '',
                video: undefined,
                videoActions: {
                    isTranscribed: false,
                    isOcred: false,
                    isSlidesMapped: false
                },
                expansionPanelModel: [0],
                tab: null,
                playlists: undefined,
                userPlaylists: undefined,
                youtube: {
                    baseUrl: 'https://www.youtube.com/embed/',
                    videoSource: ''
                },
                external: {
                    videoSource: ''
                },
                sectionInfo: {
                    obj: undefined,
                    show: false
                },
                sectionAdd: {
                    show: false,
                    name: '',
                    start: 0,
                    end: 0,
                    error: {
                        show: false,
                        text: 'Some error',
                        timeout: 3000
                    },
                    success: {
                        show: false,
                        text: 'Section added successfully',
                        timeout: 3000
                    }
                },
                keyword: '',
                keywords: [],
                keywordSections: undefined,
                keywordAdd: {
                    show: false,
                    keyword: '',
                    weight: 1
                },
                addToPlaylistDialog: {
                    show: false,
                    playlists: [],
                    selectedPlaylist: undefined
                },
                success: {
                    show: false,
                    text: '',
                    timeout: 3000
                },
                error: {
                    show: false,
                    text: '',
                    timeout: 3000
                },
                slides: [],
                // contains the frames information object
                frames: [],
                // key = frame num, value = index of obj in frames array
                frames2frames: {},
                // key = slide num, value = array of indices of obj in frames array
                slides2frames: {},
                // contains the frames objects for the slide search hits
                slideSearchHits: []
            }
        },
        methods: {
            getVideoInfo: function () {
                const context = this

                context.video = undefined

                context.$http
                    .post('/video/getVideoInfo', { id: context.id })
                    .then((response) => {
                        context.video = response.data.video
                        context.videoActions.isTranscribed = response.data.transcribed
                        context.videoActions.isOcred = response.data.ocred
                        context.videoActions.isSlidesMapped = response.data.slidesMapped

                        context.playlists = response.data.playlists

                        if (context.video.videoMetainfo.source === 'YOUTUBE') {
                            context.youtube.videoSource =
                                context.youtube.baseUrl + response.data.videoMetainfo.videoId
                        } else {
                            context.external.videoSource = context.video.videoMetainfo.url
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                        context.error.text = 'Could not get the video'
                        context.error.show = true
                    })
            },
            getPlaylists: function () {
                const context = this

                context.$http
                    .get('/video/getPlaylists')
                    .then((response) => {
                        context.addToPlaylistDialog.playlists = response.data.playlists
                    })
                    .catch((err) => {
                        console.log(err)

                        context.error.text = 'Could not get the playlist information'
                        context.error.show = true
                    })
            },
            getSlidesInformation: function () {
                const context = this

                context.$http
                    .post('/video/getSlidesInformation', {
                        videoId: context.id
                    })
                    .then((response) => {
                        context.slides = response.data.payload

                        context.slides.sort(function (a, b) {
                            if (a.slideNum < b.slideNum) {
                                return -1
                            } else if (a.slideNum > b.slideNum) {
                                return 1
                            }
                            return 0
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getFrameMapping: function () {
                const context = this

                context.$http
                    .post('/video/getFrameMapping', {
                        videoId: context.id
                    })
                    .then((response) => {
                        context.frames = []
                        context.frames2frames = {}
                        context.slides2frames = {}

                        for (let frame of response.data.payload.mappings) {
                            const index = context.frames.push(frame) - 1

                            // adds the mapping of 1 frame to 1 frame
                            context.frames2frames[frame.frameNum] = index

                            // adds the mapping of 1 slide to n frame
                            if (!(frame.slideNum in context.slides2frames)) {
                                context.slides2frames[frame.slideNum] = []
                            }
                            context.slides2frames[frame.slideNum].push(index)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            goBackToArchivePage: function () {
                this.$router.push({
                    name: 'archivePage'
                })
            },
            goToPlaylistPage(playlistId) {
                console.log('go to playlist with id: ' + playlistId)
            },
            openSectionAddDialog: function () {
                this.sectionAdd.show = true
            },
            openSectionDialog: function (section) {
                this.sectionInfo.show = true
                this.sectionInfo.obj = section
            },
            goToKeywordSearchPage: function () {
                console.log('go to search by keywords with filter by this video')
            },
            openKeywordAddDialog: function () {
                this.keywordAdd.show = true
            },
            addKeyword() {
                if (!this.$refs['addKeywordForm'].validate()) {
                    return
                }
                this.keywords.push({
                    keyword: this.keywordAdd.keyword,
                    weight: this.keywordAdd.weight
                })
                this.keywordAdd.show = false
                this.keywordAdd.keyword = ''
            },
            addKeywordInline() {
                if (this.keyword.length < 3) {
                    return
                }
                // splits the keyword at whitespace and adds the words separately
                const keywordArray = this.keyword.split(/\s+/)
                for (const kw of keywordArray) {
                    if (this.keywords.some((e) => e.keyword === kw)) {
                        continue
                    }

                    this.keywords.push({
                        keyword: kw,
                        weight: 1
                    })
                }
                this.keyword = ''
            },
            deleteKeyword(keywordObj) {
                this.keywords.splice(this.keywords.indexOf(keywordObj), 1)
            },
            searchKeywords: function () {
                const context = this

                context.keywordSections = undefined
                if (context.keywords.length === 0) {
                    return
                }

                context.$http
                    .post('/video/findSectionsByKeywordsForVideo', {
                        videoId: this.video.id,
                        keywords: context.keywords
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            context.keywordSections = []

                            if (response.data.payload.length > 0) {
                                context.keywordSections = response.data.payload

                                /*
                                context.keywordSections = context.combineSections(
                                    response.data.payload
                                )
                                */
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            openAddToPlaylistDialog: function () {
                this.addToPlaylistDialog.show = true
            },
            closeAddToPlaylistDialog: function () {
                this.addToPlaylistDialog.show = false
                this.addToPlaylistDialog.playlistId = undefined
                this.$refs['add-to-playlist-form'].resetValidation()
            },
            addToPlaylist: function () {
                if (this.$refs['add-to-playlist-form'].validate()) {
                    const context = this

                    context.$http
                        .post('/video/addItemToPlaylist', {
                            videoId: context.video.id,
                            playlistId: context.addToPlaylistDialog.selectedPlaylist
                        })
                        .then(() => {
                            context.closeAddToPlaylistDialog()

                            context.success.text = 'Successfully added the video to the playlist'
                            context.success.show = true

                            context.getVideoInfo()
                        })
                        .catch((err) => {
                            context.error.text = err.response.data.payload
                            context.error.show = true
                        })
                }
            },
            combineSections: function (sections) {
                let output = []

                sections.sort(function (a, b) {
                    if (a.startMillis < b.startMillis) {
                        return -1
                    } else if (a.startMillis > b.startMillis) {
                        return 1
                    } else {
                        return 0
                    }
                })

                console.log(sections)

                let frame = sections[0]
                for (let i = 1; i < sections.length; i++) {
                    let frame2 = sections[i]

                    if (
                        frame.endMillis >= frame2.startMillis ||
                        0 <= frame2.startMillis - frame.endMillis <= 5000
                    ) {
                        frame.endMillis = frame2.endMillis
                    } else {
                        output.push(frame)
                        frame = frame2
                    }
                }
                output.push(frame)

                return output
            },
            jumpToSection: function (section) {
                const seconds = parseInt(section.startMillis / 1000)

                this.startVideoAt(seconds, true)
            },
            jumpToFrame: function (frame) {
                const seconds = parseInt(frame.startMillis / 1000)

                this.startVideoAt(seconds, true)
            },
            jumpToSlide: function (slide) {
                this.slideSearchHits = []
                const frames = this.slides2frames[slide.slideNum]

                if (!frames || frames.length === 0) {
                    return
                }

                let tmpSections = []

                for (const frameIndex of frames) {
                    tmpSections.push(this.frames[frameIndex])
                }

                this.slideSearchHits = this.combineSections(tmpSections)
            },
            startVideoAt: function (seconds, autoplay) {
                if (this.video.videoMetainfo.source === 'YOUTUBE') {
                    let autoPlayNum = autoplay ? 1 : 0

                    this.videoSource = ''

                    const context = this
                    setTimeout(function () {
                        context.videoSource =
                            context.baseUrl +
                            context.talkInfo.videoMetainfo.videoId +
                            '?autoplay=' +
                            autoPlayNum +
                            '&start=' +
                            seconds
                    }, 200)
                } else {
                    this.$refs['video-player-external'].currentTime = seconds

                    if (autoplay) {
                        if (this.$refs['video-player-external'].paused) {
                            this.$refs['video-player-external'].play()
                        }
                    }
                }
            },
            resetSectionAddFields: function () {
                this.sectionAdd.name = ''
                this.sectionAdd.start = 0
                this.sectionAdd.end = 0
            },
            addSection: function () {
                const context = this

                if (this.$refs['addSectionForm'].validate()) {
                    context.$http
                        .post('/video/addSection', {
                            talkId: context.id,
                            name: context.sectionAdd.name,
                            startMillis: context.sectionAdd.start * 1000,
                            endMillis: context.sectionAdd.end * 1000
                        })
                        .then(() => {
                            context.sectionAdd.show = false
                            context.resetSectionAddFields()
                            this.getVideoInfo()

                            context.success.text = 'Added the section'
                            context.success.show = true
                        })
                        .catch((err) => {
                            console.log(err)

                            context.error.text = err.response.data.payload
                            context.error.show = true
                        })
                }
            },
            millisToTime: function (ms) {
                let seconds = parseInt(ms / 1000)
                let hours = parseInt(seconds / 3600)
                seconds = seconds % 3600
                let minutes = parseInt(seconds / 60)
                seconds = seconds % 60
                return (
                    (hours < 10 ? '0' + hours : hours) +
                    ':' +
                    (minutes < 10 ? '0' + minutes : minutes) +
                    ':' +
                    (seconds < 10 ? '0' + seconds : seconds)
                )
            }
        },
        created() {
            this.id = this.$route.params.id

            this.getVideoInfo()
            this.getPlaylists()
            this.getSlidesInformation()
            this.getFrameMapping()
        },
        props: {
            fromSearch: {
                type: Boolean
            }
        }
    }
</script>

<style scoped>
    #video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>