import { CustomRouter } from '../../router'
import { ApiService } from '../../services/api.service'
import { TokenService } from '../../services/token.service'

const AuthModule = {
    namespaced: true,
    state: {
        isLoggedIn: false,
        username: 'no-username',
        identity: undefined
    },
    getter: {
        isLoggedIn: function (state) {
            return state.isLoggedIn
        },
        getUsername: function (state) {
            return state.username
        }
    },
    mutations: {
        loginRequest: function () {
            // TODO: implement
        },
        loginSuccess: function (state) {
            state.isLoggedIn = true
        },
        loginFailed: function (state) {
            state.isLoggedIn = false
        },
        logoutSuccess: function (state) {
            state.isLoggedIn = false
        },
        setUsername: function (state, username) {
            state.username = username
        }
    },
    actions: {
        login: async function ({ commit }, { email, password }) {
            commit('loginRequest')

            try {
                let response = await ApiService.post('/auth/authenticateUser', {
                    email: email,
                    password: password
                })

                const token = response.data.token
                const identity = response.data.identity
                const username = response.data.username

                if (token && identity && username) {
                    TokenService.saveToken(token)
                    TokenService.setIdentity(identity)
                    ApiService.setAuthHeader(token)

                    commit('setUsername', username)
                    commit('loginSuccess')

                    return 200
                }

                return 300
            } catch (err) {
                commit('loginFailed')
                //console.log(err)

                return err.response.data.status.value
            }
        },
        verify: async function ({ commit }) {
            commit('loginRequest')

            if (!TokenService.hasToken()) {
                commit('loginFailed')
            }

            ApiService.removeAuthHeader()

            try {
                let response = await ApiService.post('/auth/verifyToken', {
                    token: TokenService.getToken()
                })

                const identity = response.data.payload.identity
                const username = response.data.payload.username

                if (identity && username) {
                    ApiService.setAuthHeader(TokenService.getToken())

                    commit('setUsername', username)
                    commit('loginSuccess')
                } else {
                    commit('loginFailed')

                    TokenService.removeToken()
                    TokenService.removeIdentity()
                    ApiService.removeAuthHeader()

                    await CustomRouter.push({
                        name: 'loginPage'
                    })
                }
            } catch (err) {
                commit('loginFailed')

                TokenService.removeToken()
                TokenService.removeIdentity()
                ApiService.removeAuthHeader()

                await CustomRouter.push({
                    name: 'loginPage'
                })
            }
        },
        setLoggedIn: function ({ commit }, { token, identity }) {
            TokenService.saveToken(token)
            TokenService.setIdentity(identity)
            ApiService.setAuthHeader(token)

            commit('loginSuccess')
        },
        logout: async function ({ commit }, { targetRoute }) {
            TokenService.removeToken()
            TokenService.removeIdentity()
            ApiService.removeAuthHeader()

            commit('logoutSuccess')

            await CustomRouter.push(targetRoute)
        }
    }
}

export { AuthModule }
