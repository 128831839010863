<template>
    <div>
        <v-container>
            <v-row justify="center">
                <v-card class="tw-w-72 tw-px-4">
                    <v-text-field
                        v-model="filter.searchQuery"
                        type="text"
                        label="Filter by Title"
                    ></v-text-field>
                </v-card>
            </v-row>

            <v-row class="tw-pt-5" justify="center">
                <v-card elevation="5" class="tw-inline-block tw-w-full">
                    <v-card-subtitle>Video Archive</v-card-subtitle>

                    <div v-if="talkList && talkList.numResults > 0">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Title</th>
                                        <th class="text-left">Author</th>
                                        <th class="text-left">Source</th>
                                        <th class="text-left">Added</th>
                                        <!--
                                        <th class="text-left">URL</th>
                                        <th class="text-left">Video ID</th>
                                        -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in talkList.talks"
                                        :key="item.id"
                                        class="tw-cursor-pointer text-xs"
                                        @click="goToSingleTalkPage(item.id)"
                                    >
                                        <td class="text-left">{{ item.videoMetainfo.title }}</td>
                                        <td class="text-left">{{ item.videoMetainfo.author }}</td>
                                        <td class="text-left">
                                            <v-chip x-small color="secondary">
                                                {{ item.videoMetainfo.source }}
                                            </v-chip>
                                        </td>
                                        <td class="text-left">
                                            {{ formatDate(item.dateAdded) }}
                                        </td>
                                        <!--
                                        <td class="text-left">{{ item.videoMetainfo.url }}</td>
                                        <td class="text-left">{{ item.videoMetainfo.videoId }}</td>
                                        -->
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-pagination
                            v-model="page.current"
                            :length="page.max"
                            :total-visible="7"
                            class="tw-mt-4"
                        ></v-pagination>
                    </div>
                    <div v-else class="tw-p-5">No entries found</div>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'SearchPage',
        data() {
            return {
                talkList: [],
                filter: {
                    title: '',
                    author: ''
                },
                page: {
                    current: 1,
                    max: 1
                }
            }
        },
        methods: {
            getVideoList: function () {
                const context = this

                context.$http
                    .post('/video/getVideoList', {
                        searchQuery: context.filter.searchQuery,
                        page: context.page.current - 1
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            context.talkList = response.data
                            context.page.max = response.data.numPages

                            if (context.page.max < context.page.current) {
                                context.page.current = Math.max(1, context.page.max)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            goToSingleTalkPage: function (id) {
                this.$router.push({
                    name: 'talkDetailsPage',
                    params: {
                        id: id,
                        fromSearch: true
                    }
                })
            },
            formatDate: function (dateString) {
                if (!dateString) {
                    return ''
                }

                let date = new Date(dateString)

                return (
                    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
                    ':' +
                    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
                    ':' +
                    (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) +
                    ' ' +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                    '.' +
                    (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
                    '.' +
                    date.getFullYear()
                )
            }
        },
        created() {
            this.getVideoList()
        },
        watch: {
            'page.current': function () {
                this.getVideoList()
            },
            'filter.searchQuery': function () {
                this.getVideoList()
            }
        }
    }
</script>

<style scoped>

</style>