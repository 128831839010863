import Vue from 'vue'
import Vuex from 'vuex'

import { AuthModule } from './modules/auth.module'

Vue.use(Vuex)

const CustomStore = new Vuex.Store({
    modules: {
        auth: AuthModule
    }
})

export { CustomStore }
