<template>
    <div>
        <video
            ref="video-player-external"
            class="tw-border-black tw-max-w-3xl"
            controls
            :src="url"
        ></video>
    </div>
</template>

<script>
    export default {
        name: 'VideoPlayerExternalPopup',
        data() {
            return {
                videoPlayer: undefined,
                videoPlayerState: 0
            }
        },
        props: {
            url: {
                type: String,
                required: true
            },
            startMillis: {
                type: Number,
                required: false,
                default: 0
            }
        },
        watch: {
            'videoPlayer.readyState': function () {
                this.videoPlayer.load()
                this.videoPlayer.currentTime = this.startMillis
                this.videoPlayer.play()
            }
        },
        mounted() {
            this.videoPlayer = this.$refs['video-player-external']

            //const context = this
            this.videoPlayer.addEventListener('loadeddata', () => {
                //console.log('in loadeddata')
                //console.log(context.videoPlayer.readyState)
                //context.videoPlayerState = context.videoPlayer.readyState
            })
        }
    }
</script>

<style scoped>

</style>