<template>
    <div>
        <div class="tw-mb-5 tw-text-left">
            <v-container>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-card class="tw-p-4 tw-w-1/2">
                        <div class="tw-text-lg tw-font-bold">Welcome to X-SIST</div>
                        <div class="tw-text-sm">
                            <div class="tw-mt-5">
                                This tool enables you to search for sections in scientific video
                                recordings by defining a custom search context using weighted
                                keywords or by lecture slide.
                            </div>

                            <div class="tw-mt-3">
                                We have currently indexed
                                {{ info ? info.numTalksInDatabase : 0 }} videos.
                            </div>

                            <div class="tw-mt-5">
                                <div class="tw-text-md tw-font-bold">
                                    Search for a specific video:
                                </div>
                                <div>
                                    Search a specific video by title in our database for further
                                    investigation.
                                </div>
                                <div class="tw-mt-2">
                                    <v-btn small color="primary" @click="switchToArchivePage"
                                        >Find video</v-btn
                                    >
                                </div>
                            </div>
                            <div class="tw-mt-5">
                                <div class="tw-text-md tw-font-bold">
                                    Query the entire database:
                                </div>
                                <div>
                                    You define the search context and we find the video sections of
                                    interest for you.
                                </div>
                                <div class="tw-mt-2">
                                    <v-btn small color="primary" @click="switchToSearchContextPage"
                                        >search database</v-btn
                                    >
                                </div>
                            </div>
                        </div>
                    </v-card>
                    <v-spacer></v-spacer>
                </v-row>

                <!--
                <v-row>
                    <v-spacer></v-spacer>
                    <v-card max-width="250" class="tw-mx-2">
                        <v-card-title>Refresh</v-card-title>
                        <v-card-subtitle>Update the queue</v-card-subtitle>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dense outlined @click="refreshIndexPage"
                                >refresh</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                    <v-card max-width="250" class="tw-mx-2">
                        <v-card-title>Videos in Database</v-card-title>
                        <v-card-text class="text-h4">{{
                            info ? info.numTalksInDatabase : 0
                        }}</v-card-text>
                    </v-card>
                    <v-spacer></v-spacer>
                </v-row>

                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-card>
                            <v-card-title>Processing queue</v-card-title>
                            <v-card-text>
                                <v-simple-table v-if="queue && queue.length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Health</th>
                                                <th class="text-left">Status</th>
                                                <th class="text-left">Source</th>
                                                <th class="text-left">Started</th>
                                                <th class="text-left">Last Change</th>
                                                <th class="text-left">Title</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in queue" :key="item.id">
                                                <td>
                                                    <v-chip
                                                        v-if="item.health === 'RUNNING'"
                                                        x-small
                                                        color="primary"
                                                    >
                                                        RUNNING
                                                    </v-chip>
                                                    <v-chip
                                                        v-else-if="item.health === 'FINISHED'"
                                                        x-small
                                                        color="green"
                                                        text-color="white"
                                                    >
                                                        FINISHED
                                                    </v-chip>
                                                    <v-chip
                                                        v-else
                                                        x-small
                                                        color="red"
                                                        text-color="white"
                                                    >
                                                        FAILED
                                                    </v-chip>
                                                </td>
                                                <td>
                                                    <v-chip x-small>
                                                        {{ item.state }}
                                                    </v-chip>
                                                </td>
                                                <td>
                                                    <v-chip x-small color="secondary">
                                                        {{ item.source }}
                                                    </v-chip>
                                                </td>
                                                <td>{{ $utils.formatDate(item.started) }}</td>
                                                <td>{{ $utils.formatDate(item.updated) }}</td>
                                                <td class="tw-w-72">{{ item.title }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <div v-else class="tw-mt-5 tw-mb-2">No items in the queue</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                -->
            </v-container>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'IndexPage',
        data() {
            return {
                queue: [],
                info: undefined
            }
        },
        methods: {
            refreshIndexPage: function () {
                this.getIndexInfo()
                this.getCurrentQueue()
            },
            getIndexInfo: function () {
                const context = this

                context.info = undefined

                context.$http
                    .get('/video/getIndexInfo')
                    .then((response) => {
                        context.info = response.data
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getCurrentQueue: function () {
                const context = this

                context.$http
                    .get('/video/getPipelineNotDone')
                    .then((response) => {
                        context.queue = response.data
                    })
                    .catch((err) => {
                        console.log(err)
                        context.queue = []
                    })
            },
            deleteQueueItem: function (id) {
                const context = this

                this.$http
                    .post('/deleteFailedPipelineItem', { id: id })
                    .then((response) => {
                        console.log(response)

                        context.getCurrentQueue()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            switchToArchivePage: function () {
                this.$router.push({
                    name: 'archivePage'
                })
            },
            switchToSearchContextPage: function () {
                this.$router.push({
                    name: 'searchContextPage'
                })
            }
        },
        computed: {
            pathIconError: function () {
                return this.$icons.iconError
            },
            pathIconOk: function () {
                return this.$icons.iconOk
            }
        },
        created() {
            this.getIndexInfo()
            this.getCurrentQueue()
        }
    }
</script>

<style scoped>

</style>