/* Defines the rules for the form text input fields */
const RulesService = {
    required: (value) => !!value || 'Required.',

    positive: (value) => value >= 0 || 'Must be positive.',
    negative: (value) => value <= 0 || 'Must be negative.',

    alphanumeric: (value) => /^\w+\d+$/.test(value) || 'Only letters and numbers allowed.',
    alphanumericWithSpaces: (value) =>
        /^([a-zA-Z0-9 ]+)$/.test(value) || 'Only letter, numbers and spaces allowed.',

    lengthAbove3: (value) => value.length >= 3 || 'At least 3 characters.',
    lengthBelow20: (value) => value.length <= 20 || 'At most 20 characters.',
    lengthBelow40: (value) => value.length <= 40 || 'At most 40 characters.'
}

export { RulesService }
