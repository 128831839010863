import Vue from 'vue'
import Router from 'vue-router'

import { TokenService } from '@/services/token.service'

Vue.use(Router)

import LoginPage from './components/LoginPage'

import IndexPage from '@/components/IndexPage'
import ArchivePage from '@/components/ArchivePage'
import SearchContextPage from '@/components/SearchContextPage'
import AddTalkPage from '@/components/AddTalkPage'
import PlaylistPage from './components/PlaylistPage'
import PlaylistDetailsPage from '@/components/PlaylistDetailsPage'

import SettingsPage from '@/components/SettingsPage'

import VideoUploadPage from '@/components/addTalk/VideoUploadPage'
import AddYoutubeVideoPage from '@/components/addTalk/AddYoutubeVideoPage'

//import SingleTalkPage from '@/components/SingleTalkPage'
import TalkDetailsPage from '@/components/TalkDetailsPage'

const CustomRouter = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            name: 'loginPage',
            component: LoginPage,
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            }
        },
        {
            path: '/',
            name: 'indexPage',
            component: IndexPage,
            meta: {
                public: false
            }
        },
        {
            path: '/searchContext',
            name: 'searchContextPage',
            component: SearchContextPage,
            meta: {
                public: false
            }
        },
        {
            path: '/archive',
            name: 'archivePage',
            component: ArchivePage,
            meta: {
                public: false
            }
        },
        {
            path: '/add',
            name: 'addTalkPage',
            component: AddTalkPage,
            meta: {
                public: false
            }
        },
        {
            path: '/add/video',
            name: 'videoUploadPage',
            component: VideoUploadPage,
            meta: {
                public: false
            }
        },
        {
            path: '/add/youtube',
            name: 'addYoutubeVideo',
            component: AddYoutubeVideoPage,
            meta: {
                public: false
            }
        },
        {
            path: '/settings',
            name: 'settingsPage',
            component: SettingsPage,
            meta: {
                public: false
            }
        },
        {
            path: '/talk/:id',
            name: 'talkDetailsPage',
            component: TalkDetailsPage,
            props: true,
            meta: {
                public: false
            }
        },
        {
            path: '/playlists',
            name: 'playlistPage',
            component: PlaylistPage,
            meta: {
                public: false
            }
        },
        {
            path: '/playlist/:id',
            name: 'playlistDetailsPage',
            component: PlaylistDetailsPage,
            meta: {
                public: false
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})

async function routerGuard(to, from, next) {
    const isPublic = to.matched.some((record) => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut)

    const isLoggedIn = TokenService.hasToken()

    if (!isLoggedIn && !isPublic) {
        return next({
            path: '/login',
            query: {
                redirect: to.fullPath
            }
        })
    }

    if (isLoggedIn && onlyWhenLoggedOut) {
        return next('/')
    }

    return next()
}

CustomRouter.beforeEach((to, from, next) => {
    return routerGuard(to, from, next)
})

export { CustomRouter }
