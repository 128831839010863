<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <v-card>
                    <div class="tw-text-sm tw-font-bold tw-my-2">
                        Search Video Database by Keywords
                    </div>
                    <v-col>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="keyword"
                                label="Keyword"
                                hint="Submit with <ENTER>"
                                @keydown.enter="addKeywordInline"
                                class="tw-w-1/3"
                            ></v-text-field>
                            <v-spacer></v-spacer>
                        </v-row>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Keyword</th>
                                        <th class="text-left">Weight</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        :key="keyword.keyword"
                                        v-for="keyword in keywords"
                                        class="tw-cursor-pointer"
                                    >
                                        <td class="tw-text-left tw-text-2xl">
                                            {{ keyword.keyword }}
                                        </td>
                                        <!--
                                    <td class="text-left">
                                        {{ keyword.weight }}
                                    </td>
                                    -->
                                        <td class="sm8">
                                            <v-slider
                                                v-model="keyword.weight"
                                                min="1"
                                                max="10"
                                                step="1"
                                                thumb-size="24"
                                                ticks
                                                thumb-label
                                                class="tw-pt-4"
                                            ></v-slider>
                                        </td>
                                        <td>
                                            <v-btn text icon>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="deleteKeyword(keyword)"
                                                        >
                                                            mdi-close
                                                        </v-icon>
                                                    </template>
                                                    <span>delete</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-if="keywords.length === 0" class="tw-mt-2 tw-text-xs">
                            No keywords entered
                        </div>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="searchKeywords">Search</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-expansion-panels multiple accordion v-model="expansionPanelModel">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="font-weight-bold">Top 5 most relevant videos:</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div
                                v-if="!relevantVideos || relevantVideos.length === 0"
                                class="tw-pt-4 tw-text-xs"
                            >
                                No videos found
                            </div>

                            <v-card
                                v-else
                                :key="index"
                                v-for="(video, index) in relevantVideos"
                                class="tw-m-2 tw-cursor-pointer hover:tw-bg-gray-100"
                                @click="goToVideoDetailsPage(video.id)"
                            >
                                <v-row no-gutters dense>
                                    <div>
                                        <img
                                            alt="thumbnail"
                                            :src="
                                                getBaseUrl() +
                                                '/video/thumbnail?videoId=' +
                                                video.id
                                            "
                                            class="tw-w-40 tw-border tw-border-gray"
                                        />
                                    </div>

                                    <v-spacer></v-spacer>
                                    <div class="tw-pt-2 tw-w-1/2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="tw-font-bold tw-truncate"
                                                >
                                                    {{ video.title }}
                                                </div>
                                            </template>
                                            <span>{{ video.title }}</span>
                                        </v-tooltip>
                                        <div class="tw-text-xs">
                                            {{ video.author }}
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                </v-row>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="font-weight-bold">Top 30 most relevant video sections:</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div
                                v-if="!queryResults || queryResults.length === 0"
                                class="tw-pt-4 tw-text-xs"
                            >
                                No videos found
                            </div>

                            <v-card
                                v-else
                                class="tw-m-4 tw-cursor-pointer hover:tw-bg-gray-100"
                                @click="openSection(result)"
                                :key="index"
                                v-for="(result, index) in queryResults"
                            >
                                <v-row no-gutters dense>
                                    <div>
                                        <img
                                            alt="thumbnail"
                                            :src="
                                                getBaseUrl() +
                                                '/video/thumbnail?videoId=' +
                                                result.videoId
                                            "
                                            class="tw-w-40 tw-border tw-border-gray"
                                        />
                                    </div>

                                    <div class="tw-text-lg tw-font-bold tw-p-2">
                                        {{ index + 1 }}
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="tw-pt-2 tw-w-1/2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="tw-font-bold tw-truncate"
                                                >
                                                    {{ result.videoTitle }}
                                                </div>
                                            </template>
                                            <span>{{ result.videoTitle }}</span>
                                        </v-tooltip>
                                        <div class="tw-text-xs">
                                            {{ result.videoAuthor }}
                                        </div>
                                        <div>
                                            {{
                                                millis2time(result.startMillis) +
                                                ' - ' +
                                                millis2time(result.endMillis)
                                            }}
                                        </div>
                                        <div class="tw-text-xs">{{ result.words.join(', ') }}</div>
                                    </div>
                                    <v-spacer></v-spacer>
                                </v-row>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <!-- dialog to add a new keyword for keyword search -->
        <v-dialog v-model="keywordAdd.show" persistent max-width="400px">
            <v-card>
                <v-card-title>Add a keyword to search</v-card-title>

                <v-form ref="addKeywordForm" class="tw-m-10">
                    <v-container>
                        <v-row>
                            <v-text-field
                                label="Keyword"
                                v-model="keywordAdd.keyword"
                                :rules="[this.$rules.required]"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-text-field
                                label="Weight"
                                type="number"
                                v-model="keywordAdd.weight"
                                :rules="[this.$rules.required, this.$rules.positive]"
                            ></v-text-field>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="error" @click="keywordAdd.show = false"
                        >Cancel</v-btn
                    >
                    <v-btn small outlined color="primary" @click="addKeyword">Add Keyword</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog to watch the selected section -->
        <v-dialog v-model="sectionPopup.show" persistent max-width="900px">
            <v-card v-if="sectionPopup.video">
                <div class="tw-mb-5 tw-border-b">
                    <div class="tw-text-xl tw-font-bold">
                        {{ sectionPopup.video.videoMetainfo.title }}
                    </div>
                    <div class="tw-text-md">{{ sectionPopup.video.videoMetainfo.author }}</div>
                </div>

                <v-row no-gutters dense>
                    <v-spacer></v-spacer>
                    <div
                        v-if="sectionPopup.video.videoMetainfo.source === 'YOUTUBE'"
                        id="video-wrapper"
                    >
                        <iframe :src="youtube.videoSource" width="100%" height="100%"></iframe>
                    </div>
                    <div v-else id="video-wrapper-external" class="tw-mx-auto">
                        <VideoPlayerExternalPopup
                            :url="sectionPopup.video.videoMetainfo.url"
                            :start-millis="sectionPopup.section.startMillis / 1000"
                        ></VideoPlayerExternalPopup>
                        <!--
                        <VideoPlayerExternalPopup
                            :url="sectionPopup.url"
                            :start-millis="sectionPopup.section.startMillis / 1000"
                        ></VideoPlayerExternalPopup>
                        -->
                    </div>
                    <v-spacer></v-spacer>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="primary" @click="closeSection">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import VideoPlayerExternalPopup from '@/components/VideoPlayerExternalPopup'

    export default {
        name: 'SearchContextPage',
        components: { VideoPlayerExternalPopup },
        data() {
            return {
                expansionPanelModel: [],
                keyword: '',
                keywords: [],
                queryResults: [],
                relevantVideos: [],
                keywordAdd: {
                    show: false,
                    keyword: '',
                    weight: 1
                },
                sectionPopup: {
                    show: false,
                    section: undefined,
                    video: undefined
                }
            }
        },
        methods: {
            getBaseUrl: function () {
                return this.$http.getBaseUrl()
            },
            openKeywordAddDialog: function () {
                this.keywordAdd.show = true
            },
            addKeywordInline() {
                if (this.keyword.length < 3) {
                    return
                }
                // splits the keyword at whitespace and adds the words separately
                const keywordArray = this.keyword.split(/\s+/)
                for (const kw of keywordArray) {
                    if (this.keywords.some((e) => e.keyword === kw)) {
                        continue
                    }

                    this.keywords.push({
                        keyword: kw,
                        weight: 1
                    })
                }

                this.keyword = ''
            },
            addKeyword() {
                if (!this.$refs['addKeywordForm'].validate()) {
                    return
                }
                this.keywords.push({
                    keyword: this.keywordAdd.keyword,
                    weight: this.keywordAdd.weight
                })
                this.keywordAdd.show = false
                this.keywordAdd.keyword = ''
            },
            deleteKeyword(keywordObj) {
                this.keywords.splice(this.keywords.indexOf(keywordObj), 1)
            },
            searchKeywords: function () {
                const context = this

                if (context.keywords.length === 0) {
                    context.relevantVideos = []
                    context.queryResults = []
                    return
                }

                context.$http
                    .post('/video/findSectionsByKeywordsForArchive', {
                        keywords: context.keywords
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            context.queryResults = response.data.payload
                            context.relevantVideos = []

                            for (let entry of context.queryResults) {
                                let tmp = {
                                    id: entry.videoId,
                                    title: entry.videoTitle,
                                    author: entry.videoAuthor
                                }

                                if (context.relevantVideos.some((e) => e.id === entry.videoId)) {
                                    continue
                                } else {
                                    context.relevantVideos.push(tmp)
                                }

                                if (context.relevantVideos.length === 5) {
                                    break
                                }
                            }

                            context.expansionPanelModel = [0, 1]
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            getVideoInfo: function (videoId) {
                const context = this

                context.$http
                    .post('/video/getVideoInfo', {
                        id: videoId
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.video.videoMetainfo.urlLocal) {
                                response.data.video.videoMetainfo.url =
                                    context.$http.getBaseUrl() +
                                    '/video/stream/' +
                                    response.data.video.id
                            }

                            context.sectionPopup.video = response.data.video
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            goToVideoDetailsPage: function (videoId) {
                const routeData = this.$router.resolve({
                    name: 'talkDetailsPage',
                    params: {
                        id: videoId
                    }
                })

                window.open(routeData.href, '_blank')
            },
            openSection: function (section) {
                this.sectionPopup.show = true
                this.sectionPopup.section = section

                this.getVideoInfo(section.videoId)
            },
            closeSection: function () {
                this.sectionPopup.show = false
                this.sectionPopup.video = undefined
            },
            millis2time: function (millis) {
                return new Date(millis).toISOString().slice(11, 19)
            }
        }
    }
</script>

<style scoped>

</style>