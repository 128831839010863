import { ApiService } from './api.service'

const TOKEN_KEY = 'session_token_talkcontextualizer'
let TOKEN = undefined
let IDENTITY = undefined

const TokenService = {
    loadToken: function () {
        TOKEN = localStorage.getItem(TOKEN_KEY)
    },
    hasToken: function () {
        return !!TOKEN
    },
    getToken: function () {
        return TOKEN
    },
    saveToken: function (token) {
        TOKEN = token
        localStorage.setItem(TOKEN_KEY, TOKEN)
    },
    removeToken: function () {
        TOKEN = undefined
        localStorage.removeItem(TOKEN_KEY)
    },
    verifyToken: async function () {
        if (!this.hasToken) {
            return undefined
        }

        try {
            let response = await ApiService.post('/auth/verifyToken', {
                token: this.getToken()
            })

            const identity = response.data.body.identity

            if (!identity) {
                return undefined
            }

            IDENTITY = identity
            return identity
        } catch (err) {
            return undefined
        }
    },
    setIdentity: function (identity) {
        IDENTITY = identity
    },
    removeIdentity: function () {
        IDENTITY = undefined
    },
    getIdentity: function () {
        return IDENTITY
    }
}

export { TokenService }
