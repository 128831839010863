<template>
    <div class="tw-text-left">
        <div class="tw-flex tw-flex-row tw-mt-20">
            <div class="tw-flex tw-flex-1"></div>
            <v-row>
                <v-col>
                    <v-card class="mx-auto" max-width="344" elevation="5">
                        <v-card-title>Add a YouTube video</v-card-title>
                        <v-card-text>
                            Enter the Link to the video and directly add it to the database
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text outlined color="deep-purple" @click="switchToYoutubePage"
                                >Add</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col>
                    <v-card class="mx-auto" max-width="344" elevation="5">
                        <v-card-title>Add external video</v-card-title>
                        <v-card-text
                            >Add a video to the database that is hosted on another external
                            website.</v-card-text
                        >
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                outlined
                                color="deep-purple"
                                @click="switchToExternalVideoPage"
                                >Add</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col>
                    <v-card class="mx-auto" max-width="344" elevation="5">
                        <v-card-title>Upload a video</v-card-title>
                        <v-card-text>
                            Upload a video from your local disk and add it to the database
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                outlined
                                color="deep-purple"
                                @click="switchToVideoUploadPage"
                                >Add</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div class="tw-flex tw-flex-1"></div>
        </div>

        <v-dialog v-model="youtube.show" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add a YouTube video</span>
                </v-card-title>
                <v-card-text class="tw-mt-4">
                    <v-container>
                        <v-row>
                            <v-text-field
                                v-model="youtube.url"
                                label="YouTube URL"
                                placeholder="https://www.youtube.com/watch?v="
                                outlined
                                dense
                            ></v-text-field>
                        </v-row>
                        <v-row v-if="youtube.isLoading">
                            <v-spacer></v-spacer>
                            <v-progress-circular indeterminate color="purple"></v-progress-circular>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-col v-if="youtube.meta">
                            <v-row>
                                <v-card-subtitle>Video information:</v-card-subtitle>
                            </v-row>

                            <v-row>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td class="tw-text-right font-weight-bold">
                                                    Title
                                                </td>
                                                <td class="tw-text-left">
                                                    {{ youtube.meta.title }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="tw-text-right font-weight-bold">
                                                    Channel
                                                </td>
                                                <td class="tw-text-left">
                                                    {{ youtube.meta.channel }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="tw-text-right font-weight-bold">
                                                    Uploaded
                                                </td>
                                                <td class="tw-text-left">
                                                    {{ formatDate(youtube.meta.uploadTimestamp) }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="tw-text-right font-weight-bold">
                                                    Views
                                                </td>
                                                <td class="tw-text-left">
                                                    {{ youtube.meta.views }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="tw-text-right font-weight-bold">
                                                    Duration
                                                </td>
                                                <td class="tw-text-left">
                                                    {{
                                                        youtube.meta.duration
                                                            .substr(2)
                                                            .replace('H', 'h ')
                                                            .replace('M', 'm ')
                                                            .replace('S', 's ')
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>

                            <v-row class="tw-pt-6">
                                <v-card-subtitle>Options:</v-card-subtitle>
                            </v-row>
                            <v-row>
                                <v-checkbox
                                    v-model="youtube.doTranscribe"
                                    :label="
                                        'Automatically transcribe (ca. ' +
                                        calculatePrice(youtube.meta.duration) +
                                        '€)'
                                    "
                                ></v-checkbox>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" outlined text @click="closeYoutubeDialog"> Close </v-btn>
                    <v-btn
                        v-if="youtube.meta"
                        color="deep-purple"
                        outlined
                        text
                        @click="uploadYoutubeVideo"
                    >
                        Add to database
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="external.show" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add external video</span>
                </v-card-title>
                <v-card-text class="tw-mt-4">
                    <v-container>
                        <v-form ref="addExternalForm">
                            <v-row>
                                <v-text-field
                                    v-model="external.title"
                                    label="Video Title"
                                    placeholder="A Brief History of Time"
                                    outlined
                                    dense
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="external.author"
                                    label="Video Author"
                                    placeholder="Stephen Hawking"
                                    outlined
                                    dense
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="external.url"
                                    label="Video URL"
                                    placeholder="https://some-hoster.com/video"
                                    outlined
                                    dense
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-row>
                        </v-form>
                    </v-container>

                    <v-container v-if="external.url" class="tw-mb-4">
                        <v-row>
                            <v-spacer></v-spacer>
                            Video preview
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row class="tw-mb-2">
                            <v-spacer></v-spacer>
                            <v-btn x-small outlined @click="previewExternalVideo">refresh</v-btn>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <video ref="preview-video" width="320" height="240" controls>
                                <source :src="external.url" type="video/mp4" />
                            </video>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" outlined text @click="closeExternalVideoDialog">
                            Close
                        </v-btn>
                        <v-btn color="deep-purple" outlined text @click="uploadExternalVideo">
                            Add to database
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- not available popup for add local video -->
        <v-snackbar v-model="naPopup.show" :timeout="naPopup.timeout" color="red accent-2" tile top>
            {{ naPopup.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="naPopup.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- error popup for youtube error -->
        <v-snackbar
            v-model="youtube.errorPopup.show"
            :timeout="youtube.errorPopup.timeout"
            color="red accent-2"
            tile
            top
        >
            {{ youtube.errorPopup.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="youtube.errorPopup.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- success popup for youtube upload -->
        <v-snackbar
            v-model="youtube.successPopup.show"
            :timeout="youtube.successPopup.timeout"
            color="success"
            tile
            top
        >
            {{ youtube.successPopup.text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="youtube.successPopup.show = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- success popup for external upload -->
        <v-snackbar
            v-model="external.successPopup.show"
            :timeout="external.successPopup.timeout"
            color="success"
            tile
            top
        >
            {{ external.successPopup.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="external.successPopup.show = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: 'AddTalkPage',
        data() {
            return {
                naPopup: {
                    show: false,
                    text: 'Not available yet..',
                    timeout: 5000
                },
                youtube: {
                    show: false,
                    url: '',
                    doTranscribe: false,
                    isLoading: false,
                    meta: undefined,
                    successPopup: {
                        show: false,
                        timeout: 5000,
                        text: 'Successfully added the YouTube video to the pipeline'
                    },
                    errorPopup: {
                        show: false,
                        timeout: 2000,
                        text: ''
                    }
                },
                external: {
                    show: false,
                    title: '',
                    author: '',
                    url: '',
                    isLoading: false,
                    meta: undefined,
                    successPopup: {
                        show: false,
                        timeout: 5000,
                        text: 'Successfully added the external video to the database'
                    }
                },
                rules: {
                    required: (value) => !!value || 'Required.'
                }
            }
        },
        methods: {
            switchToVideoUploadPage: function () {
                this.naPopup.show = true
                /*
                this.$router.push({
                    name: 'videoUploadPage'
                })
                */
            },
            switchToExternalVideoPage: function () {
                this.external.show = true
            },
            closeExternalVideoDialog: function () {
                this.external.show = false
            },
            uploadExternalVideo: function () {
                const context = this

                if (this.$refs['addExternalForm'].validate()) {
                    context.$http
                        .post('/video/upload/external', {
                            title: context.external.title,
                            author: context.external.author,
                            url: context.external.url
                        })
                        .then((response) => {
                            console.log(response)

                            context.external.successPopup.show = true

                            context.external.show = false
                            context.$refs['addExternalForm'].reset()
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            },
            switchToYoutubePage: function () {
                this.youtube.show = true
                /*
                this.$router.push({
                    name: 'addYoutubeVideo'
                })
                */
            },
            closeYoutubeDialog: function () {
                this.youtube.url = ''
                this.youtube.show = false
                this.youtube.meta = false
                this.youtube.isLoading = false
            },
            previewExternalVideo: function () {
                this.$refs['preview-video'].load()

                /*
                let source = document.createElement('source')
                source.setAttribute('src', this.external.url)
                source.setAttribute('type', 'video/mp4')

                this.$refs['preview-video'].appendChild()
                */
            },
            getYoutubeVideoMeta: function () {
                const context = this

                if (!context.youtube.url.startsWith('https://www.youtube.com/watch?v=')) {
                    return
                }

                context.youtube.isLoading = true

                context.$http
                    .post('/video/upload/youtubeMeta', {
                        url: context.youtube.url
                    })
                    .then((response) => {
                        console.log(response.data)

                        context.youtube.meta = response.data
                        context.youtube.isLoading = false
                    })
                    .catch(() => {
                        context.youtube.meta = undefined
                        context.youtube.isLoading = false
                    })
            },
            uploadYoutubeVideo: function () {
                const context = this

                context.$http
                    .post('/video/upload/youtube', {
                        url: context.youtube.url,
                        doTranscribe: context.youtube.doTranscribe
                    })
                    .then(() => {
                        context.youtube.successPopup.show = true
                        context.closeYoutubeDialog()
                    })
                    .catch((err) => {
                        if (err.response.data.status.value === 601) {
                            context.youtube.errorPopup.text = 'Video already in database'
                            context.youtube.errorPopup.show = true
                        } else {
                            context.youtube.errorPopup.text = 'Error! Could not upload the video'
                            context.youtube.errorPopup.show = true
                        }

                        context.closeYoutubeDialog()
                    })
            },
            calculatePrice: function (duration) {
                const pattern = new RegExp('PT(?:(\\d+)H)?(?:(\\d+)M)?(?:(\\d+)S)?')
                const match = pattern.exec(duration)

                let totalSeconds = 0

                if (match[1]) {
                    totalSeconds += parseInt(match[1]) * 60 * 60
                }
                if (match[2]) {
                    totalSeconds += parseInt(match[2]) * 60
                }
                if (match[3]) {
                    totalSeconds += parseInt(match[3])
                }

                return Math.round(parseInt(totalSeconds / 15) * 0.006 * 0.88 * 100) / 100
            },
            formatDate: function (dateString) {
                if (!dateString) {
                    return ''
                }

                let date = new Date(dateString)

                return (
                    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
                    ':' +
                    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
                    ':' +
                    (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()) +
                    ' ' +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                    '.' +
                    (date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
                    '.' +
                    date.getFullYear()
                )
            }
        },
        watch: {
            'youtube.url': function () {
                this.getYoutubeVideoMeta()
            }
        }
    }
</script>

<style scoped>

</style>