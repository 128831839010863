import axios from 'axios'

const ApiService = {
    init: function (baseURL) {
        axios.defaults.baseURL = baseURL
    },
    getBaseUrl: function () {
        return axios.defaults.baseURL
    },
    setAuthHeader: function (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer: ' + token
    },
    removeAuthHeader: function () {
        axios.defaults.headers.common['Authorization'] = ''
    },
    setHeader: function (name, value) {
        axios.defaults.headers.common[name] = value
    },
    removeHeader: function (name) {
        axios.defaults.headers.common[name] = ''
    },
    get: function (url) {
        return axios.get(url)
    },
    post: function (url, body) {
        return axios.post(url, body)
    }
}

export { ApiService }
